import { ref, reactive } from "vue";
import { AFCandLCApproveTableData } from "@/views/Payment/Mgmt/types";
import * as API from "@/API/payment";
import downloadFile from "@/utils/payment/downloadFile";
import { ApproveData } from "@/views/Payment/types";
import { message } from "ant-design-vue";
import { useStore } from "vuex";
import { useRoute } from "vue-router";
const useApproveAFCandLC = () => {
  const store = useStore();
  const route = useRoute();
  const state = reactive({
    selectedRowKeys: ref<number[]>(), // Check here to configure the default column
    selectedRows: ref<AFCandLCApproveTableData[]>([]),
    approveComment: "",
    rejectComment: "",
    payoutRound: "",
    status: "",
    pageNumber: 1,
    pageSize: 10,
  });
  // 打开筛选条件
  const isOpen = ref<boolean>(false);
  // 筛选面板
  const activeKey = ref<string>("");
  const total = ref<number>(0);
  // 是否显示失败原因面板
  const showFailFlag = ref<boolean>(false);
  // 状态下拉框
  //const approvalStatusArray = ref<DealerStatusData[]>([])
  // 激励适用性
  // const rebateFlagArray = ref<DealerStatusData[]>([])
  // 批量查询
  const batchQuery = reactive({
    batchQueryTitle: "",
    batchQueryModal: false,
  });
  const columns = reactive([
    { title: "Payout Round", dataIndex: "payoutRound", width: 260 },
    {
      title: "Payout Code",
      className: "column-no-wrap",
      dataIndex: "payoutCode",
      key: "model",
      width: 160,
    },
    {
      title: "Vin No.",
      dataIndex: "vinNo",
      key: "modelYear",
      width: 100,
      align: "right",
      slots: { customRender: "vinNo" },
    }, //
    {
      title: "Payout Amount w VAT",
      dataIndex: "payoutAmountWVat",
      width: 150,
      align: "right",
      slots: { customRender: "payoutAmountWVat" },
    }, //
    {
      title: "Status",
      dataIndex: "status",
      width: 110,
      key: "usage",
      align: "center",
    },
    {
      title: "Submission Date",
      dataIndex: "submissionDate",
      width: 120,
      key: "submissionDate",
      align: "center",
    },
    {
      title: "Approval Path",
      fixed: "right",
      align: "left",
      width: 150,
      slots: { customRender: "action" },
    },
  ]);
  // 查询区数据：包括 Year|Payout Round|Payount Status
  const initPayoutRoundSelectedlist = ref<any>();

  // 筛选条件
  const queryParams = reactive({
    /*year:"",
        payoutCode:"",
        payStatus:"",
        currentPage:1,
        pageSize:20*/
  });
  //获取payoutRound数据,AFC AND LC
  const getApprovePayoutRoundSelectedData = async (
    type: string,
    role: string,
    callback?: any
  ) => {
    const params = {
      searchType: role,
      userId: store.state.user.usrid,
    };
    let res;
    if (type === "pending") {
      res = await API.getPayoutRoundListNotApproved(params);
    } else {
      res = await API.getPayoutRoundListApproved(params);
    }
    // const res = await API.getApprovePayoutSelectedData({params:{
    //         type:type,role:role}})
    initPayoutRoundSelectedlist.value = res;
    state.payoutRound = res[0];
    if (callback) {
      callback();
    }
  };
  // 校验列表
  const tableData = ref<AFCandLCApproveTableData[]>([]);
  const getTableData = () => {
    console.log("列表的查询条件是：", state.payoutRound);
    if (
      route.name === "paymentApproveMBAFC" ||
      route.name === "paymentApproveMBLC"
    ) {
      state.payoutRound = state.payoutRound ? state.payoutRound : "";
      if (route.name === "paymentApproveMBAFC") {
        API.getFinanceAFCPendingList({
          pageNumber: state.pageNumber,
          pageSize: state.pageSize,
          typeList: [1],
          userId: store.state.user.usrid,
          payoutRound: state.payoutRound,
        }).then((res: any) => {
          console.log("返回列表数据：", res);
          tableData.value = res.content;
          total.value = res.totalElements;
          state.pageSize = res.size;
        });
      } else {
        API.getFinanceLCPendingList({
          pageNumber: state.pageNumber,
          pageSize: state.pageSize,
          typeList: [1],
          userId: store.state.user.usrid,
          payoutRound: state.payoutRound,
        }).then((res: any) => {
          console.log("返回列表数据：", res);
          tableData.value = res.content;
          total.value = res.totalElements;
          state.pageSize = res.size;
        });
      }
    } else {
      const params = {
        month: "",
        pageNumber: state.pageNumber,
        pageSize: state.pageSize,
        payoutCode: "",
        payoutRound: state.payoutRound,
        typeList: [],
        userId: store.state.user.usrid,
        year: "",
      };
      if (route.name === "paymentTrackingMBLC") {
        API.getLCTrackingList(params).then((res: any) => {
          console.log("返回列表数据：", res);
          tableData.value = res.content;
          total.value = res.totalElements;
          state.pageSize = res.size;
        });
      } else {
        API.getAFCTrackingList(params).then((res: any) => {
          console.log("返回列表数据：", res);
          tableData.value = res.content;
          total.value = res.totalElements;
          state.pageSize = res.size;
        });
      }
    }
  };
  const mulSearchEvent = (rowSelection: any) => {
    if (!state.payoutRound) {
      state.payoutRound = "all";
    }
    state.payoutRound = state.payoutRound ? state.payoutRound : "";
    state.pageNumber = 1;
    rowSelection.selectedRowKeys = [];
    rowSelection.selectedRowData = [];
    getTableData();
  };
  const pageChange = (page: number, pageSize: number, rowSeclection: any) => {
    state.pageNumber = page > 0 ? page : 1;
    state.pageSize = pageSize;
    rowSeclection.selectedRowKeys = [];
    getTableData();
  };
  const sizeChange = (page: number, pageSize: number, rowSelection: any) => {
    state.pageNumber = page > 0 ? page : 1;
    state.pageSize = pageSize;
    rowSelection.selectedRowKeys = [];
    getTableData();
  };
  const handleClick = (event: MouseEvent) => {
    activeKey.value = "";
    event.stopPropagation();
  };
  const handleActiveKey = () => {
    if (activeKey.value.indexOf("3") !== -1) {
      //
    } else {
      //showCollapsed.value = falsse;
    }
  };
  const handleReject = (rowSelection: any) => {
    return new Promise((resolve, reject) => {
      API.fetchPaymentReject(
        rowSelection.selectedRowData.map((item: any) => {
          return {
            rejectComment: state.rejectComment,
            delegationUserId: item.delegationUserId,
            id: item.id,
            requestUserId: item.requestUserId,
          };
        })
      ).then((res: any) => {
        if (res.code == "0") {
          resolve(res);
        } else {
          reject(res);
        }
      });
    });
  };
  const handleApprove = (rowSelection: any) => {
    return new Promise((resolve, reject) => {
      console.log(state.selectedRows, 2222);
      API.fetchPaymentApprove(
        rowSelection.selectedRowData.map((item: any) => {
          return {
            approveComment: state.approveComment,
            delegationUserId: item.delegationUserId,
            id: item.id,
            requestUserId: item.requestUserId,
          };
        })
      ).then((res: any) => {
        if (res.code == "0") {
          resolve(res);
        } else {
          reject(res);
        }
      });
    });
  };
  const downloadFilesClick = () => {
    const params = {
      url: `/ptapi/clickPayoutRound/${state.payoutRound}`,
      method: "get",
      params: {},
    };
    downloadFile(params).then(() => {
      // loading.value = false;
      // downloadModalVisible.value = false;
      // emptyVisible.value = false;
    });
  };

  const approveList = ref<ApproveData[]>();
  const approveVisible = ref(false);
  const approveClick = (record: any) => {
    approveList.value = [];
    // API.getApprovalPackage(record.id).then((res: any) => {
    //     if (res.code === '0') {
    //         approveList.value = res.data.nodeList
    //         approveVisible.value = true
    //     } else {
    //         message.error(res.message)
    //     }
    // })

    const params = {
      approvalType: "PayoutApproval",
      payoutCodeId: record.id,
      payoutType: "FL",
    };
    API.getApprovalPackageByCreated(params).then((res: any) => {
      if (res.code === "0") {
        approveList.value = res.data.map((item: any) => item.nodeList);
        approveVisible.value = true;
      } else {
        message.error(res.message);
      }
    });
  };

  return {
    getTableData,
    mulSearchEvent,
    pageChange,
    sizeChange,
    handleClick,
    getApprovePayoutRoundSelectedData,
    tableData,
    state,
    initPayoutRoundSelectedlist,
    columns,
    total,
    handleReject,
    handleApprove,
    downloadFilesClick,
    approveVisible,
    approveList,
    approveClick,
  };
};
export default useApproveAFCandLC;
