
import { defineComponent, reactive, ref, toRefs, computed, nextTick, watch, WatchSource } from "vue";
import useApproveAFCandLC from "@/hooks/payment/MbafcAndMblc/useApproveAFCandLC";
import {message} from "ant-design-vue";
import { ColumnProps } from 'ant-design-vue/es/table/interface';
type Key = ColumnProps['key'];
import {useRoute, useRouter, onBeforeRouteLeave} from "vue-router";
import { useStore } from "vuex";
import {AFCandLCApproveTableData} from "@/views/Payment/Mgmt/types";
import ApprovalModal from "@/views/Payment/components/approvalModal.vue";
import { toThousands } from "@/utils/approve";
import { toThousandsAndTwoDecimal } from '@/utils/payment/numberFormat'
import {Modal} from "ant-design-vue";
import ApproveSteps from "@/views/Payment/components/ApproveSteps.vue";
const tableWidth = window.innerWidth
const tableHeight = window.innerHeight-420
export default defineComponent({
  name: 'paymentApproveMBAFC',
  components: {
    ApprovalModal,
    ApproveSteps
  },
  setup() {
    const router = useRouter()
    const route = useRoute()
    const {getApprovePayoutRoundSelectedData,pageChange,state, sizeChange,initPayoutRoundSelectedlist,getTableData,columns,tableData,total,handleApprove,handleReject, downloadFilesClick,
      approveVisible, approveClick, approveList} = useApproveAFCandLC()
    const currentRole = useStore().state.user.fulnm;
    const toTrackingStatus = router.currentRoute.value.query.toTracking
    const routeName =  ref<any>()
    const routeQuery = ref<any>()
    const isHasRejectButton = ref<boolean>(false)
    const isHasApproveButton = ref<boolean>(false)
    const rowSelection = reactive<{ selectedRowKeys: Key[]; selectedRowData: AFCandLCApproveTableData[]; onChange: Function;columnWidth: string}>({
      onChange: (selectedRowKeys: Key[], selectedRows: AFCandLCApproveTableData[]) => {
        rowSelection.selectedRowKeys = selectedRowKeys;
        rowSelection.selectedRowData = selectedRows;
      },
      selectedRowKeys: [],
      selectedRowData: [],
      columnWidth: '20px'
    })
    const mulSearchEvent = (rowSelection: any) => {
      rowSelection.selectedRowKeys = []
      rowSelection.selectedRowData = []
      getTableData()
    }
    watch([()=>route.name,()=>route.query],(newV: any)=>{
      console.log('子页面route侦听',newV)
      routeName.value = newV[0]
      routeQuery.value = newV[1]
      if (routeName.value === 'paymentApproveMBAFC'){
        console.log('监听当前路由MBAFC')
        columns[4].dataIndex = 'status'
        state.status = 'Submitted'
        isHasApproveButton.value = false
        isHasRejectButton.value = false
        getApprovePayoutRoundSelectedData('pending',  'AFC', () => {
          mulSearchEvent(rowSelection)
        });
      }
      if (routeName.value === 'paymentApproveMBLC'){
        console.log('监听当前路由MBLC')
        columns[4].dataIndex = 'status'
        getApprovePayoutRoundSelectedData('pending',  'LC', () => {
          mulSearchEvent(rowSelection)
        });
        state.status = 'Submitted'
        isHasApproveButton.value = false
        isHasRejectButton.value = false
      }
      if (routeName.value === 'paymentTrackingMBAFC'){
        console.log('监听当前路由TrackingMBAFC')
        columns[4].dataIndex = 'payoutStatus'
        getApprovePayoutRoundSelectedData('tracking',  'AFC', ()=> {
          mulSearchEvent(rowSelection)
        });
        state.status = 'Approved'
        isHasApproveButton.value = true
        isHasRejectButton.value = true
      }
      if (routeName.value === 'paymentTrackingMBLC'){
        columns[4].dataIndex = 'payoutStatus'
        console.log('监听当前路由TrackingMBLC')
        getApprovePayoutRoundSelectedData('tracking',  'LC', () => {
          mulSearchEvent(rowSelection)
        });
        state.status = 'Approved'
        isHasApproveButton.value = true
        isHasRejectButton.value = true
        
      }
    },{
      immediate:true
    })
    watch(
        () => state.payoutRound,
        (newVal) => {
          console.log("enter watch........",newVal)
          if (!newVal)
            state.payoutRound = ''
        },{
          immediate:false
        }
    );
    
    const handlePageChange = (page: number,pageSize: number) =>{
      pageChange(page,pageSize,rowSelection)
    }
    const handleSizeChange = (page: number,pageSize: number) =>{
      sizeChange(page,pageSize,rowSelection)
    }
    const approvalModalVisible = ref<boolean>(false);
    const approvalType = ref<string>('approve');
    const clearSelection = () => {
      rowSelection.selectedRowKeys = [];
      rowSelection.selectedRowData = [];
    };
    const handleCommentsConfirm = (comments: string, next: Function) => {
      const closeModal = () =>{
        approvalModalVisible.value = false
        next()
        clearSelection()
      }
      if (approvalType.value == 'reject'){
        console.log('selectedRowKeys.value',rowSelection.selectedRowKeys)
        state.selectedRowKeys = rowSelection.selectedRowKeys as number[]
        console.log('state.selectedRowKeys',state.selectedRowKeys)
        state.rejectComment = comments
        handleReject(rowSelection).then(() => {
          closeModal()
          Modal.info({
            title: 'Tips',
            content: 'Successfully Reject！'//res.data.message
          })
          mulSearchEvent(rowSelection)
        }).catch((res: any) => {
          closeModal()
          Modal.error({
            title: 'Tips',
            content: res.message
          })
        })
      }
      if (approvalType.value == 'approve'){
        state.selectedRowKeys= rowSelection.selectedRowKeys as number[]
        state.approveComment = comments
        handleApprove(rowSelection).then(() => {
          closeModal()
          Modal.info({
            title: 'Tips',
            content: 'Successfully Approve！'//res.data.message
          })
          mulSearchEvent(rowSelection)
        }).catch((res: any) => {
          closeModal()
          Modal.error({
            title: 'Tips',
            content: res.message
          })
        })
      }
    };
    const onReject = () => {
      if ((!rowSelection.selectedRowData || rowSelection.selectedRowData.length === 0)) {
        message.warning('Please select the data first');
      } else {
        approvalType.value = 'reject'
        approvalModalVisible.value = true
      }
    }
    const onApprove = () => {
      if ((!rowSelection.selectedRowData || rowSelection.selectedRowData.length === 0)) {
        message.warning('Please select the data first');
      } else {
        approvalType.value = 'approve'
        approvalModalVisible.value = true
      }
    }
    onBeforeRouteLeave(() => {
      rowSelection.selectedRowKeys = []
      rowSelection.selectedRowData = []
      tableData.value = []
    })
    return {
      ...toRefs(state),
      pageChange,
      sizeChange,
      getApprovePayoutRoundSelectedData,
      tableWidth,
      tableHeight,
      handlePageChange,
      handleSizeChange,
      initPayoutRoundSelectedlist,
      columns,
      tableData,
      mulSearchEvent,
      rowSelection,
      total,
      isHasApproveButton,
      isHasRejectButton,
      toThousands,
      toThousandsAndTwoDecimal,
      onReject,
      onApprove,
      approvalModalVisible,
      approvalType,
      handleCommentsConfirm,
      state,
      downloadFilesClick,
      approveVisible,
      approveClick,
      approveList
    }
  }
});
